
export default {
    state: () => ({
        token: '',
        user_info: {
            'uid': -1,
            'username': '超级牛管理员',
            // 原始数据
            'raw': {},
        },
    }),
    // 同步写操作，不写缓存
    mutations: {
        setUserInfo(state, userInfo) {
            state['user_info'] = userInfo;
        },
        setToken(state, token) {
            state['token'] = token;
        },

    },
    // 异步写操作，同时写缓存
    actions: {
        setUserInfo(context, userInfo) {
            context.commit('setUserInfo', userInfo);
            localStorage.setItem('user_info', JSON.stringify(userInfo));
        },
        setToken(context, token) {
            context.commit('setToken', token);
            localStorage.setItem('token', token);
        },
    },
    // 读操作，自动判断状态或读缓存
    getters: {
        getUserInfo: (state) => {
            if (state.user_info.uid === -1) {
                const userInfo = JSON.parse(localStorage.getItem('userInfo'));
                if (userInfo)
                    state.user_info = userInfo;
            }
            return state.user_info;
        },
        getToken: (state) => {
            if (!state.token) {
                const token = localStorage.getItem('token');
                if (token)
                    state.token = token;
            }
            return state.token;
        },
    },
};
