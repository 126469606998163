
import axios from 'axios';
// import store from '@/store';
// import {getToken} from '@/utils/auth'
const rootUrl = '';
// const rootUrl = 'http://smart-venue.hzhuishi.cn';
// const rootUrl = 'http://118.31.122.163:8090';
// const rootUrl = 'http://192.168.0.100:25100';


export default function ajax(config, isLoading = true) {
    const url = rootUrl + (config.url || '');
    const param = config.param || {};
    const method = (config.method || 'GET').toUpperCase();
    const headers = {
        'Authorization': sessionStorage.getItem('authorization'),
        ...config.headers
    };
    
    // 参数 data只适用于这些请求方法 'PUT', 'POST', 和 'PATCH'
    const prarmKey = method === 'POST' || method === 'PUT' || method === 'PATCH' ? 'data' : 'params';
    
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: method,
            [prarmKey]: param, // 是即将与请求一起发送的 URL 参数
            timeout: 15000,
            
            // `headers` 是即将被发送的自定义请求头
            headers: headers,
            // `auth` 表示应该使用 HTTP 基础验证，并提供凭据
            // 这将设置一个 `Authorization` 头，覆写掉现有的任意使用 `headers` 设置的自定义 `Authorization`头
            // auth: {
            //     username: 'janedoe',
            //     password: 's00pers3cret'
            // },
        })
        .then(res => {
            if (res.status === 200) {
                // console.log('ajax:', res);
                if(res.headers && res.headers.authorization){
                    // console.log('authorization:', res.headers.authorization);
                    sessionStorage.setItem('authorization', res.headers.authorization);
                }
                
                if (res.data.err === 0 || res.data.code == 200 || res.data.code === 0 || res.data.status === 'ok') {
                    // console.log('请求成功:', res);
                    // resolve(res.data.data ? res.data.data : {});
                    resolve(res.data.data ? res.data.data : res.data );
                }
                else if(res.data.code === 404){
                    // sessionStorage.clear();
                    // this.$router.push({path: "/userlogin"});
                    reject(res);
                }
                else {
                    // console.log('服务器连接正确，但返回接口或参数错误:', res.data.msg ? res.data.msg : res);
                    reject(res);
                }
            }
            else {
                // console.log('网络错误:', res.status, res);
                reject(res);
            }
        })
        .catch(err => {
            // console.log('网络地址没有响应或请求失败:', config, err);
            reject(err);
        });
    });


}

