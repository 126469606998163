import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store.js';
import ajax from './ajax.js';

// ����ElementUI�⣬����
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './static/css/element-ui.css'; 
Vue.use(ElementUI);

import './mock-data.js';

Vue.config.productionTip = false;

Vue.prototype.$ajax = ajax;

new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app');
