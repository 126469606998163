import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: () => import('./pages/home/home.vue'),
        },
        {
            path: '/login',
            component: () => import('./pages/login/login.vue'),
        },
    ],
});

export default router;
