
let Mock = require('mockjs');


Mock.mock('/api/getConfig', 'get', param => {
    // console.log("Mock: /api/getConfig:", param)
    let res = {};
    res.layoutConfig = {
        direction: 'column',
        children: [
            {
                slot: 'header',
                class: 'header',
            },
            {
                slot: 'left-area',
                class: 'left-area',
            },
            {
                slot: 'right-area',
                class: 'right-area',
            },
            {
                slot: 'center-up-area',
                class: 'center-up-area',
            },
            {
                slot: 'center-down-area',
                class: 'center-down-area',
            },
            {
                slot: 'footer',
                class: 'footer',
            },
        ]
    }
    res.moduleList = [
        { title: "日期时间", module: 'ComTime', component: 'com-time', path: './com-time.vue', slot: 'left-area', },
        // { module: 'ZjScrollList', component: 'zj-scroll-list', path: 'zj-scroll-list', slot: 'left-area', window: '', },
        // { title: "巡检人员", module: 'ZjScrollList', component: 'zj-scroll-list', path: 'zj-scroll-list', slot: 'left-area', window: 'zj-widget', },
        { title: "设备列表", module: 'ComDevices', component: 'com-devices', path: './com-devices.vue', slot: 'left-area', window: 'zj-widget', },
        { title: "线路传感器状态", module: 'ComLines', component: 'com-lines', path: './com-lines.vue', slot: 'left-area', window: 'zj-widget', },
        { title: "值班人员表", module: 'ComSentries', component: 'com-sentries', path: './com-sentries.vue', slot: 'left-area', window: 'zj-widget', },
        { title: "环境参数监测", module: 'ComShowWeather', component: 'com-show-weather', path: './com-show-weather.vue', slot: 'right-area', window: 'zj-widget', },
        { title: "接头端子温度预警响应", module: 'ComEarlyWarnings', component: 'com-early-warnings', path: './com-early-warnings.vue', slot: 'right-area', window: 'zj-widget', },
        { title: "通流回路设备监测", module: 'ComFlowLoop', component: 'com-flow-loop', path: './com-flow-loop.vue', slot: 'right-area', window: 'zj-widget', },
        { title: "巡检单兵人员", module: 'ComSoldiers', component: 'com-soldiers', path: './com-soldiers.vue', slot: 'right-area', window: 'zj-widget', },
        { title: "三维模型", module: 'ComThreeModel', component: 'com-three-model', path: './com-three-model.vue', slot: 'center-up-area', window: '', },
        { title: "VR全景", module: 'ComVrPanorama', component: 'com-vr-panorama', path: './com-vr-panorama.vue', slot: 'center-up-area', window: '', },
        { title: "线路历史数据", module: 'ComLinesHistory', component: 'com-lines-history', path: './com-lines-history.vue', slot: 'center-up-area', window: 'zj-drag-widget', },
    
    ];
    return Mock.mock({
        err: 0,
        data: res,
    });
});



Mock.mock('/api/soldier', 'get', () => {
    return Mock.mock({
        err: 0,
        data: {
            "data|100": [
                {
                    "name": "@cname",
                    "add_id": 10,
                    "phone": /13\d{9}/,
                    "position": "副队长",
                    "department_name": "卫生局一部门",
                    "dep_id": 74,
                    "short_number": 1001,
                    "coordinate": "374",
                    "soldier_id": 38,
                    "soldier_address": "",
                    "address": "",
                    "remark": null,
                    "lng": "120.414143",
                    "lat": "30.81608"
                },
            ],
            "count": 6
        }
    });
});



// // 设备概览
// Mock.mock('/api/overview', 'get', () => {
//     return Mock.mock({
//         status: "ok",
//         data: [
//             {
//                 "channel": 0,
//                 "id": 2214776852,
//                 "temperature": 34.874725341796875,
//                 "timestamp": "2021-10-29T14:21:32.180"
//             },
//             {
//                 "channel": 0,
//                 "id": 1028528923,
//                 "temperature": 24,
//                 "timestamp": "2021-10-29T14:21:32.180"
//             },
//         ],
//     });
// });



Mock.mock('/api/early_warnings', 'get', () => {
    return Mock.mock({
        err: 0,
        "data|20": [
            {name: "接口端子@integer(1, 20)", status: /(预警)|(报警)|(正常)|(其它)/, time: "2021年@date(MM月dd日)"},
        ]
    });
});


Mock.mock('/api/flow_loop', 'get', () => {
    return Mock.mock({
        err: 0,
        "data|20": [
            {name: "接口端子@integer(1, 20)", status: /(成功)|(警告)|(失败)|(其它)/, },

        ]
    });
});



Mock.mock('/api/show_duty_screen', 'post', () => {
    return Mock.mock({
        err: 0,
        "data|100": [
            {
                "captain_name": "@cname",
                "phone": /13[\d]{9}/,
                "group_name": "@city",
                "date": "@date('MM月dd日')",
            },
        ]
    });
});



Mock.mock('/api/getInfo', 'get', () => {
    return Mock.mock({
        err: 0,
        data: {
            "running_time|1-150": 0,
            "item_count|1000-100000": 0,
        }
    });
});



Mock.mock('/api/login', 'post', options => {
    // console.log("mockData: login:", options);
    let param = JSON.parse(options.body);
    let responent = {};
    if(!param.username){
        responent = {
            err: 4001,
            msg: '请输入用户名！',
        }
        return Mock.mock(responent);
    }
    if(!param.password){
        responent = {
            err: 4002,
            msg: '请输入密码！',
        }
        return Mock.mock(responent);
    }
    let userList = [
        {username: 'admin', password: '12345678'},
        {username: 'test', password: '12345678'},
        {username: 'guest', password: '12345678'},
    ];
    
    let correct = null;
    correct= userList.find(row => {
        return param.username === row.username && param.password=== row.password;
    })
    // console.log("mockData: login:", correct);
    if(correct){
        responent = {
            err: 0,
            msg: '成功',
            data: {token: 'mock_token' + Math.round(Math.random()*100000)}
        }
    }
    else{
        responent = {
            err: 4005,
            msg: '账号或密码不正确',
        }
    }
    
    return Mock.mock(responent);
});





// 设备历史记录
Mock.mock('/api/history', 'get', () => {
    return Mock.mock({
        status: "ok",
        data: [
            {
                "channel": 0,
                "id": 0,
                "ping": 0,
                "temperature": 34.418155670166016,
                "timestamp": "2021-10-29T14:21:01.664"
            }, 
        ],
    });
});