<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
body{margin: 0; padding: 0; -moz-user-select: none; -khtml-user-select: none; user-select: none;}
ul,ol,li{margin: 0; padding: 0; list-style: none;}
#app {
    margin: 0; padding: 0;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    
}
</style>
