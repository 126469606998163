import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import modUser from './store/mod-user.js';

const store = new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        modUser,
    },
});

export default store;
